<template>
  <div>
    <v-tabs
      v-model="currentTab"
      centered
    >
      <v-tab>Fridge Logs</v-tab>
      <v-tab>Cleaning Log</v-tab>
      <v-tab>Equipment Calibration</v-tab>
      <v-tab>Quality Assurance</v-tab>
    </v-tabs>
    <v-tabs-items v-model="currentTab">
      <v-tab-item :key="1">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-btn
                  color="success"
                  @click="downloadFridge()"
                >
                  Download Report
                  <v-icon
                    dark
                    right
                  >
                    {{ icons.mdiCloudDownload }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="4"
                offset-md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :key="logsDashKey"
            :loading="loadingComplete"
            class="table-kitchen-sink"
            :search="search"
            :headers="fridgeHeaders"
            :items="allFridge"
            :sort-by="['date', 'time']"
            :sort-desc="[true, true]"
            item-key="_id"
          >
            <template
              v-for="fridgeHeader in fridgeHeaders.filter((fridgeHeader) =>
                fridgeHeader.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${fridgeHeader.value}`]="{ header, value }"
            >
              {{ fridgeHeader.formatter(value) }}
            </template>
            <template #[`item.currentTemp`]="{item}">
              {{ `${item.currentTemp}` }} <sup>o</sup>C
            </template>
            <template #[`item.minTemp`]="{item}">
              {{ `${item.minTemp}` }} <sup>o</sup>C
            </template>
            <template #[`item.maxTemp`]="{item}">
              {{ `${item.maxTemp}` }} <sup>o</sup>C
            </template>
            <template
              v-for="fridgeHeade in fridgeHeaders.filter((fridgeHeade) =>
                fridgeHeade.hasOwnProperty('checker')
              )"
              v-slot:[`item.${fridgeHeade.value}`]="{ header, value }"
            >
              <v-icon color="success">
                {{ `${fridgeHeade.checker(value)}` }}
              </v-icon>
            </template>
            <template #[`item.delete`]="{ item, index }">
              <v-icon
                medium
                class="me-3"
                @click="deleteItem(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <!-- action -->
            <!-- <template #[`item.amendCompleted`]="{ item }">
                        <v-icon medium class="me-3" @click="loadPatientProfile(item['patientID'], item['_id'])">
                            {{ icons.mdiPencilOutline }}
                        </v-icon>
                    </template>
                    <template #[`item.duplicateCompleted`]="{ item }">
                        <v-icon medium class="me-3" @click="duplicate(item['patientID'], item['_id'])">
                            {{ icons.mdiContentDuplicate}}
                        </v-icon>
                    </template>
                    <template #[`item.dlPDF`]="{ item, index }">
                        <v-icon medium class="me-3" @click="downloadPdf(item)">
                            {{ icons.mdiCloudDownload}}
                        </v-icon>
                    </template> -->
            <template v-slot:no-data>
              <h1> No Logs in Databse</h1>
              <v-btn
                color="primary"
                @click="allFridgeTrue"
              >
                Refresh
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="2">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-btn
                  color="success"
                  @click="downloadCleaning()"
                >
                  Download Report
                  <v-icon
                    dark
                    right
                  >
                    {{ icons.mdiCloudDownload }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="4"
                offset-md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loadingComplete"
            class="table-kitchen-sink"
            :search="search"
            :headers="cleaningHeaders"
            :items="allCleaning"
            :sort-by="['date', 'time']"
            :sort-desc="[true, true]"
          >
            <template
              v-for="cleaningHeader in cleaningHeaders.filter((cleaningHeader) =>
                cleaningHeader.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${cleaningHeader.value}`]="{ header, value }"
            >
              {{ cleaningHeader.formatter(value) }}
            </template>
            <template
              v-for="cleaningHeade in cleaningHeaders.filter((cleaningHeade) =>
                cleaningHeade.hasOwnProperty('checker')
              )"
              v-slot:[`item.${cleaningHeade.value}`]="{ header, value }"
            >
              <v-icon color="success">
                {{ `${cleaningHeade.checker(value)}` }}
              </v-icon>
            </template>
            <template #[`item.delete`]="{ item, index }">
              <v-icon
                medium
                class="me-3"
                @click="deleteItemCleaning(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:no-data>
              <h1> No Logs in Databse</h1>
              <v-btn
                color="primary"
                @click="allCleaningTrue"
              >
                Refresh
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="3">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-btn
                  color="success"
                  @click="downloadEquipment()"
                >
                  Download Report
                  <v-icon
                    dark
                    right
                  >
                    {{ icons.mdiCloudDownload }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="4"
                offset-md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loadingComplete"
            class="table-kitchen-sink"
            :search="search"
            :headers="equipmentHeaders"
            :items="allEquipment"
            :sort-by="['date', 'time']"
            :sort-desc="[true, true]"
          >
            <template
              v-for="equipmentHeader in equipmentHeaders.filter((equipmentHeader) =>
                equipmentHeader.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${equipmentHeader.value}`]="{ header, value }"
            >
              {{ equipmentHeader.formatter(value) }}
            </template>
            <template
              v-for="equipmentHeade in equipmentHeaders.filter((equipmentHeade) =>
                equipmentHeade.hasOwnProperty('checker')
              )"
              v-slot:[`item.${equipmentHeade.value}`]="{ header, value }"
            >
              <v-icon color="success">
                {{ `${equipmentHeade.checker(value)}` }}
              </v-icon>
            </template>
            <template #[`item.delete`]="{ item, index }">
              <v-icon
                medium
                class="me-3"
                @click="deleteItemEquipment(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:no-data>
              <h1> No Logs in Databse</h1>
              <v-btn
                color="primary"
                @click="allEquipmentTrue"
              >
                Refresh
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item :key="4">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                <v-btn
                  color="success"
                  @click="downloadQuality()"
                >
                  Download Report
                  <v-icon
                    dark
                    right
                  >
                    {{ icons.mdiCloudDownload }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                md="4"
                offset-md="4"
              >
                <v-text-field
                  v-model="search"
                  :append-icon="icons.mdiMagnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loadingComplete"
            class="table-kitchen-sink"
            :search="search"
            :headers="qualityHeaders"
            :items="allQuality"
            :sort-by="['date', 'time']"
            :sort-desc="[true, true]"
          >
            <template
              v-for="qualityHeader in qualityHeaders.filter((qualityHeader) =>
                qualityHeader.hasOwnProperty('formatter')
              )"
              v-slot:[`item.${qualityHeader.value}`]="{ header, value }"
            >
              {{ qualityHeader.formatter(value) }}
            </template>
            <template
              v-for="qualityHeade in qualityHeaders.filter((qualityHeade) =>
                qualityHeade.hasOwnProperty('checker')
              )"
              v-slot:[`item.${qualityHeade.value}`]="{ header, value }"
            >
              <v-icon color="success">
                {{ `${qualityHeade.checker(value)}` }}
              </v-icon>
            </template>
            <template #[`item.delete`]="{ item, index }">
              <v-icon
                medium
                class="me-3"
                @click="deleteItemQuality(item)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <template v-slot:no-data>
              <h1> No Logs in Databse</h1>
              <v-btn
                color="primary"
                @click="allQualityTrue"
              >
                Refresh
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- dialog -->
    <!-- fridge -->
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDelete"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirm"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="Deleteconfirm"
      max-width="500px"
    >
      <v-alert type="warning">
        Fridge Log Record Deleted Successfully
      </v-alert>
    </v-dialog>
    <!-- cleaning -->
    <v-dialog
      v-model="dialogDeleteCleaning"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDeleteCleaning"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirmCleaning"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="DeleteconfirmCleaning"
      max-width="500px"
    >
      <v-alert type="warning">
        Cleaning Log Record Deleted Successfully
      </v-alert>
    </v-dialog>
    <!-- equipment -->
    <v-dialog
      v-model="dialogDeleteEquipment"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDeleteEquipment"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirmEquipment"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="DeleteconfirmEquipment"
      max-width="500px"
    >
      <v-alert type="warning">
        Equipment Log Record Deleted Successfully
      </v-alert>
    </v-dialog>
    <!-- quality -->
    <v-dialog
      v-model="dialogDeleteQuality"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          Are you sure you want to delete this item?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="closeDeleteQuality"
          >
            Cancel
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItemConfirmQuality"
          >
            OK
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="DeleteconfirmQuality"
      max-width="500px"
    >
      <v-alert type="warning">
        Quality Log Record Deleted Successfully
      </v-alert>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiContentDuplicate,
  mdiCloudDownload,
  mdiMagnify,
  mdiCheckCircle,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import { mapGetters } from 'vuex'
import { pharmacyloggerService } from '@/render/api/zarya'
import { momentTimeZone, fileDownload } from '@/util/functions/index'

export default {
  name: 'PharmacyLoggerDashboard',
  setup() {
    const search = ref('')
    const currentTab = ref(0)

    return {
      search,
      currentTab,
    }
  },
  data() {
    return {
      logsDashKey: 0,
      loading: true,
      loadingComplete: true,
      loadingInProcess: false,
      singleSelect: true,
      selectedInProgress: [],
      loadingData: false,
      fullLogList: [],
      allFridge: [],
      allCleaning: [],
      allEquipment: [],
      allQuality: [],
      filteredItems: [],
      tab: 'v-step-2',
      candidatesList: [],
      isDataPulling: true,
      fridgeHeaders: [
        {
          text: 'Date (YYYY-MM-DD)',
          value: 'date',
          width: '15%',
          formatter: x => (x ? momentTimeZone(x) : null),
          sortable: true,
          align: 'center',
        },
        {
          text: 'Time',
          value: 'time',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Current Temp',
          value: 'currentTemp',
          align: 'center',
        },
        {
          text: 'Minimum Temp',
          value: 'minTemp',
          align: 'center',
        },
        {
          text: 'Maximum Temp',
          value: 'maxTemp',
          align: 'center',
        },
        {
          text: 'Temprerature Reset',
          value: 'temprReset',
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Delete',
          value: 'delete',
          align: 'center',
        },
      ],
      cleaningHeaders: [
        {
          text: 'Date (MM-DD-YYYY)',
          value: 'date',
          width: '15%',
          formatter: x => (x ? momentTimeZone(x) : null),
          sortable: true,
          align: 'center',
        },
        {
          text: 'Time',
          value: 'time',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Floors Cleaned',
          value: 'floors',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },

        {
          text: 'Counters Cleaned',
          value: 'counters',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'Equipment Cleaned',
          value: 'equipment',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Delete',
          value: 'delete',
          align: 'center',
        },
      ],
      equipmentHeaders: [
        {
          text: 'Date (MM-DD-YYYY)',
          value: 'date',
          width: '15%',
          formatter: x => (x ? momentTimeZone(x) : null),
          sortable: true,
          align: 'center',
        },
        {
          text: 'Time',
          value: 'time',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Scale Calibrated',
          value: 'scale',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'Methadone Pump Calibrated',
          value: 'methadone',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Delete',
          value: 'delete',
          align: 'center',
        },
      ],
      qualityHeaders: [
        {
          text: 'Date (MM-DD-YYYY)',
          value: 'date',
          width: '15%',
          formatter: x => (x ? momentTimeZone(x) : null),
          sortable: true,
          align: 'center',
        },
        {
          text: 'Time',
          value: 'time',
          width: '10%',
          sortable: true,
        },
        {
          text: 'Near-Miss & Incident Reports',
          value: 'incident',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'Narcotic Sales',
          value: 'narcoticSales',
          sortable: false,
          align: 'center',
          checker: x => (x ? mdiCheckCircle : null),
        },
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Delete',
          value: 'delete',
          align: 'center',
        },
      ],

      // materio

      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiContentDuplicate,
        mdiCloudDownload,
        mdiMagnify,
        mdiCheckCircle,
      },

      // fridge
      dialog: false,
      dialogDelete: false,
      Deleteconfirm: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},

      // cleaning
      dialogCleaning: false,
      dialogDeleteCleaning: false,
      DeleteconfirmCleaning: false,
      editedIndexCleaning: -1,
      editedItemCleaning: {},
      defaultItemCleaning: {},

      // equipment
      dialogEquipment: false,
      dialogDeleteEquipment: false,
      DeleteconfirmEquipment: false,
      editedIndexEquipment: -1,
      editedItemEquipment: {},
      defaultItemEquipment: {},

      // quality
      dialogQuality: false,
      dialogDeleteQuality: false,
      DeleteconfirmQuality: false,
      editedIndexQuality: -1,
      editedItemQuality: {},
      defaultItemQuality: {},
    }
  },
  computed: {
    ...mapGetters(['UserProfile', 'PharmacyProfile']),
  },
  watch: {
    // fridge
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },

    Deleteconfirm(val) {
      val || this.closeDeleteConfirm()
    },

    // cleaning
    dialogCleaning(val) {
      val || this.closeCleaning()
    },
    dialogDeleteCleaning(val) {
      val || this.closeDeleteCleaning()
    },

    DeleteconfirmCleaning(val) {
      val || this.closeDeleteConfirmCleaning()
    },

    // equipment
    dialogEquipment(val) {
      val || this.closeEquipment()
    },
    dialogDeleteEquipment(val) {
      val || this.closeDeleteEquipment()
    },

    DeleteconfirmEquipment(val) {
      val || this.closeDeleteConfirmEquipment()
    },

    // quality
    dialogQuality(val) {
      val || this.closeQuality()
    },
    dialogDeleteQuality(val) {
      val || this.closeDeleteQuality()
    },

    DeleteconfirmQuality(val) {
      val || this.closeDeleteConfirmQuality()
    },
  },
  mounted() {
    this.loadFullLogList()
    this.allFridgeTrue()
    this.allCleaningTrue()
    this.allEquipmentTrue()
    this.allQualityTrue()
    this.$root.$on('Refresh_Log', tab => {
      this.allFridgeTrue()
      this.loadFullLogList()
      this.allCleaningTrue()
      this.allEquipmentTrue()
      this.allQualityTrue()
      this.forceRerender()

      // console.log('Message received from Logger Modal')
    })
  },

  methods: {
    forceRerender() {
      this.logsDashKey += 1
    },
    async loadFullLogList() {
      const token = await this.$auth.getTokenSilently()
      this.fullLogList = await pharmacyloggerService.getAllLog(token)
      this.loadingComplete = false
      this.loadingInProcess = false
    },
    async allFridgeTrue() {
      const token = await this.$auth.getTokenSilently()
      this.allFridge = await pharmacyloggerService.getAllFridgeTrueLogs(token)
      this.loadingComplete = false
      this.loadingInProcess = false
    },
    async allCleaningTrue() {
      const token = await this.$auth.getTokenSilently()
      this.allCleaning = await pharmacyloggerService.getAllCleaningTrueLogs(token)
      this.loadingComplete = false
      this.loadingInProcess = false
    },
    async allEquipmentTrue() {
      const token = await this.$auth.getTokenSilently()
      this.allEquipment = await pharmacyloggerService.getAllEquipmentTrueLogs(token)
      this.loadingComplete = false
      this.loadingInProcess = false
    },
    async allQualityTrue() {
      const token = await this.$auth.getTokenSilently()
      this.allQuality = await pharmacyloggerService.getAllQualityTrueLogs(token)
      this.loadingComplete = false
      this.loadingInProcess = false
    },
    downloadFridge() {
      const propList = ['date', 'time', 'user', 'currentTemp', 'minTemp', 'maxTemp', 'temprReset']

      // console.log('proplist', propList)
      const csvHeaders = [
        ['Date', 'Time', 'User', 'Current Temp', 'Minimum Temp', 'Maximum Temp', 'Temprerature Reset'],
      ]
      const csvData = this.allFridge.map(row =>
        propList.map(prop => {
          try {
            return typeof prop === 'string' ? row[prop] : prop(row)
          } catch (e) {
            throw new Error(`Error row: ${JSON.stringify(row)}`)
          }
        }),
      )
      const current = new Date()
      const dateNow = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      fileDownload(
        [...csvHeaders, ...csvData].map(e => e.join(',')).join('\n'),
        `fridge_log_export_${dateNow}`,
        'text/csv',
        'csv',
      )
    },

    downloadCleaning() {
      const propList = ['date', 'time', 'user', 'floors', 'counters', 'equipment']

      // console.log('proplist', propList)
      const csvHeaders = [['Date', 'Time', 'User', 'Floors Cleaned', 'Counters Cleaned', 'Equipment Cleaned']]
      const csvData = this.allCleaning.map(row =>
        propList.map(prop => {
          try {
            return typeof prop === 'string' ? row[prop] : prop(row)
          } catch (e) {
            throw new Error(`Error row: ${JSON.stringify(row)}`)
          }
        }),
      )
      const current = new Date()
      const dateNow = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      fileDownload(
        [...csvHeaders, ...csvData].map(e => e.join(',')).join('\n'),
        `cleaning_log_export_${dateNow}`,
        'text/csv',
        'csv',
      )
    },

    downloadEquipment() {
      const propList = ['date', 'time', 'user', 'scale', 'methadone']

      // console.log('proplist', propList)
      const csvHeaders = [['Date', 'Time', 'User', 'Scale Calibrated', 'Methadone Pump Calibrated']]
      const csvData = this.allEquipment.map(row =>
        propList.map(prop => {
          try {
            return typeof prop === 'string' ? row[prop] : prop(row)
          } catch (e) {
            throw new Error(`Error row: ${JSON.stringify(row)}`)
          }
        }),
      )
      const current = new Date()
      const dateNow = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      fileDownload(
        [...csvHeaders, ...csvData].map(e => e.join(',')).join('\n'),
        `equipment_log_export_${dateNow}`,
        'text/csv',
        'csv',
      )
    },

    downloadQuality() {
      const propList = ['date', 'time', 'user', 'incident', 'narcoticSales']

      // console.log('proplist', propList)
      const csvHeaders = [['Date', 'Time', 'User', 'Near-Miss & Incident Reports', 'Narcotic Sales']]
      const csvData = this.allQuality.map(row =>
        propList.map(prop => {
          try {
            return typeof prop === 'string' ? row[prop] : prop(row)
          } catch (e) {
            throw new Error(`Error row: ${JSON.stringify(row)}`)
          }
        }),
      )
      const current = new Date()
      const dateNow = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`
      fileDownload(
        [...csvHeaders, ...csvData].map(e => e.join(',')).join('\n'),
        `quality_log_export_${dateNow}`,
        'text/csv',
        'csv',
      )
    },

    editItem(item) {
      this.editedIndex = this.userList.indexOf(item)
      this.editedItem = {
        ...item,
      }
      this.dialog = true
    },

    // delete fridge item
    deleteItem(item) {
      this.editedIndex = this.allFridge.indexOf(item)
      this.editedItem = {
        ...item,
      }
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      const token = await this.$auth.getTokenSilently()
      this.logId = this.allFridge[this.editedIndex]._id
      pharmacyloggerService.DeleteLogById(this.logId, token)
      this.allFridge.splice(this.editedIndex, 1)

      // console.log(this.stateID)
      this.closeDelete()
      this.Deleteconfirm = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {
          ...this.defaultItem,
        }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {
          ...this.defaultItem,
        }
        this.editedIndex = -1
      })
    },
    closeDeleteConfirm() {
      this.Deleteconfirm = false

      // this.$nextTick(() => {
      //   this.editedItem = {
      //     ...this.defaultItem,
      //   }
      //   this.editedIndex = -1
      // })
    },

    // delete Cleaning item
    deleteItemCleaning(item) {
      this.editedIndexCleaning = this.allCleaning.indexOf(item)
      this.editedItemCleaning = {
        ...item,
      }
      this.dialogDeleteCleaning = true
    },

    async deleteItemConfirmCleaning() {
      const token = await this.$auth.getTokenSilently()
      this.logId = this.allCleaning[this.editedIndexCleaning]._id
      pharmacyloggerService.DeleteLogById(this.logId, token)
      this.allCleaning.splice(this.editedIndexCleaning, 1)

      // console.log(this.stateID)
      this.closeDeleteCleaning()
      this.DeleteconfirmCleaning = true
    },

    closeCleaning() {
      this.dialogCleaning = false
      this.$nextTick(() => {
        this.editedItemCleaning = {
          ...this.defaultItemCleaning,
        }
        this.editedIndexCleaning = -1
      })
    },

    closeDeleteCleaning() {
      this.dialogDeleteCleaning = false
      this.$nextTick(() => {
        this.editedItemCleaning = {
          ...this.defaultItemCleaning,
        }
        this.editedIndexCleaning = -1
      })
    },
    closeDeleteConfirmCleaning() {
      this.DeleteconfirmCleaning = false

      // this.$nextTick(() => {
      //   this.editedItem = {
      //     ...this.defaultItem,
      //   }
      //   this.editedIndex = -1
      // })
    },

    // delete Equipment item
    deleteItemEquipment(item) {
      this.editedIndexEquipment = this.allEquipment.indexOf(item)
      this.editedItemEquipment = {
        ...item,
      }
      this.dialogDeleteEquipment = true
    },

    async deleteItemConfirmEquipment() {
      const token = await this.$auth.getTokenSilently()
      this.logId = this.allEquipment[this.editedIndexEquipment]._id
      pharmacyloggerService.DeleteLogById(this.logId, token)
      this.allEquipment.splice(this.editedIndexEquipment, 1)

      // console.log(this.stateID)
      this.closeDeleteEquipment()
      this.DeleteconfirmEquipment = true
    },

    closeEquipment() {
      this.dialogEquipment = false
      this.$nextTick(() => {
        this.editedItemEquipment = {
          ...this.defaultItemEquipment,
        }
        this.editedIndexEquipment = -1
      })
    },

    closeDeleteEquipment() {
      this.dialogDeleteEquipment = false
      this.$nextTick(() => {
        this.editedItemEquipment = {
          ...this.defaultItemEquipment,
        }
        this.editedIndexEquipment = -1
      })
    },
    closeDeleteConfirmEquipment() {
      this.DeleteconfirmEquipment = false

      // this.$nextTick(() => {
      //   this.editedItem = {
      //     ...this.defaultItem,
      //   }
      //   this.editedIndex = -1
      // })
    },

    // delete Quality item
    deleteItemQuality(item) {
      this.editedIndexQuality = this.allQuality.indexOf(item)
      this.editedItemQuality = {
        ...item,
      }
      this.dialogDeleteQuality = true
    },

    async deleteItemConfirmQuality() {
      const token = await this.$auth.getTokenSilently()
      this.logId = this.allQuality[this.editedIndexQuality]._id
      pharmacyloggerService.DeleteLogById(this.logId, token)
      this.allQuality.splice(this.editedIndexQuality, 1)

      // console.log(this.stateID)
      this.closeDeleteQuality()
      this.DeleteconfirmQuality = true
    },

    closeQuality() {
      this.dialogQuality = false
      this.$nextTick(() => {
        this.editedItemQuality = {
          ...this.defaultItemQuality,
        }
        this.editedIndexQuality = -1
      })
    },

    closeDeleteQuality() {
      this.dialogDeleteQuality = false
      this.$nextTick(() => {
        this.editedItemQuality = {
          ...this.defaultItemQuality,
        }
        this.editedIndexQuality = -1
      })
    },
    closeDeleteConfirmQuality() {
      this.DeleteconfirmQuality = false

      // this.$nextTick(() => {
      //   this.editedItem = {
      //     ...this.defaultItem,
      //   }
      //   this.editedIndex = -1
      // })
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.userList[this.editedIndex], this.editedItem)
      } else {
        this.userList.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
